export default [
  {
    path: 'login',
    name: 'auth-login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Login.vue')
  },
  {
    path: 'recover-password',
    name: 'auth-password-recovery',
    component: () =>
      import(
        /* webpackChunkName: "recover-password" */
        '@/views/auth/PasswordRecovery.vue'
      )
  },
  {
    path: 'reset-password',
    name: 'auth-password-reset',
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */
        '@/views/auth/ResetPassword.vue'
      )
  }
]
