import auth from './auth'
import survey from './survey'
import user from './user'

export const api = {
  auth,
  survey,
  user
}

export default api
