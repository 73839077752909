import axios from 'axios'
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules'
import api from '@/api'
import router from '@/router'
import store from '@/store'
import { getLocalToken, removeLocalToken, saveLocalToken } from '@/utils/index'

import { IUserProfile } from '@/interfaces/users'
import { IAppNotification } from '@/interfaces/common'

@Module
export class MainModule extends VuexModule {
  token = ''
  isLoggedIn: boolean | null = null
  logInError = false
  userProfile: IUserProfile | null = null
  notifications: IAppNotification[] = []

  firstNotification() {
    return this.notifications.length > 0 && this.notifications[0]
  }

  @Mutation
  setToken(payload: string): void {
    this.token = payload
  }

  @Mutation
  setLoggedIn(payload: boolean): void {
    this.isLoggedIn = payload
  }

  @Mutation
  setLogInError(payload: boolean): void {
    this.logInError = payload
  }

  @Mutation
  setUserProfile(payload: IUserProfile): void {
    this.userProfile = payload
  }

  @Mutation
  addNotification(payload: IAppNotification): void {
    this.notifications.push(payload)
  }

  @Mutation
  removeNotification(payload: IAppNotification): void {
    this.notifications = this.notifications.filter((notification) => notification !== payload)
  }

  @Action
  async actionLogIn(payload: { username: string; password: string }): Promise<void> {
    try {
      const response = await api.auth.logInGetToken(payload.username, payload.password)
      const token = response.data.access_token
      if (token) {
        saveLocalToken(token)
        this.setToken(token)
        this.setLoggedIn(true)
        this.setLogInError(false)
        await this.actionGetUserProfile()
        await this.actionRouteLoggedIn()

        this.addNotification({
          content: 'Logged in',
          color: 'success'
        })
      } else {
        await this.actionLogOut
      }
    } catch (err) {
      this.setLogInError(true)
      await this.actionLogOut()
    }
  }

  @Action
  async actionGetUserProfile(): Promise<void> {
    try {
      const response = await api.auth.getMe(this.token)
      if (response.data) {
        this.setUserProfile(response.data)
      }
    } catch (error) {
      await this.actionCheckApiError(error)
    }
  }

  @Action
  async actionUpdateUserProfile(payload: any): Promise<void> {
    try {
      const loadingNotification = {
        content: 'saving',
        showProgress: true
      }
      this.addNotification(loadingNotification)
      const response = (
        await Promise.all([
          api.auth.updateMe(this.token, payload),
          await new Promise<void>((resolve) => setTimeout(() => resolve(), 500))
        ])
      )[0]
      this.setUserProfile(response.data)
      this.removeNotification(loadingNotification)
      this.addNotification({
        content: 'Profile successfully updated',
        color: 'success'
      })
    } catch (error) {
      await this.actionCheckApiError(error)
    }
  }

  @Action
  async actionCheckLoggedIn(): Promise<void> {
    if (!this.isLoggedIn) {
      let token = this.token
      if (!token) {
        const localToken = getLocalToken()
        if (localToken) {
          this.setToken(localToken)
          token = localToken
        }
      }
      if (token) {
        try {
          const response = await api.auth.getMe(token)
          this.setLoggedIn(true)
          this.setUserProfile(response.data)
        } catch (error) {
          await this.actionRemoveLogIn()
        }
      } else {
        await this.actionRemoveLogIn()
      }
    }
  }

  @Action
  async actionRemoveLogIn(): Promise<void> {
    removeLocalToken()
    this.setToken('')
    this.setLoggedIn(false)
  }

  @Action
  async actionLogOut(): Promise<void> {
    await this.actionRemoveLogIn()
    await this.actionRouteLogOut()
  }

  @Action
  async actionUserLogOut(): Promise<void> {
    await this.actionLogOut()
    this.addNotification({
      content: 'Logged out',
      color: 'success'
    })
  }

  @Action
  actionRouteLogOut(): void {
    if (router.currentRoute.name !== 'auth-login') {
      router.push({ name: 'auth-login' })
    }
  }

  @Action
  async actionCheckApiError(payload: any): Promise<void> {
    if (axios.isAxiosError(payload)) {
      if (payload.response!.status === 401) {
        await this.actionLogOut()
      }
    } else {
      throw payload
    }
  }

  @Action
  actionRouteLoggedIn(): void {
    if (router.currentRoute.name === 'auth-login' || router.currentRoute.name === 'root') {
      router.push({ name: 'admin' })
    }
  }

  @Action
  async actionAddNotification(payload: {
    notification: IAppNotification
    timeout: number
  }): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.addNotification(payload.notification)
        resolve()
      }, payload.timeout)
    })
  }

  @Action
  async actionRemoveNotification(payload: {
    notification: IAppNotification
    timeout: number
  }): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.removeNotification(payload.notification)
        resolve()
      }, payload.timeout)
    })
  }
}

export const mainModule = new MainModule({ store, name: 'main' })
export default mainModule
