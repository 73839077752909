// import '@babel/polyfill'
// Import Component hooks before component definitions
import '@/component-hooks'

import Vue from 'vue'

import App from '@/App.vue'
import vuetify from '@/plugins/vuetify'
import router from '@/router/index'
import store from '@/store'

import '@/plugins/survey'

import '@/validation'
import '@/filters'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
