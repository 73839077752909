const env = process.env.VUE_APP_ENV

const envApiUrl = ''

if (env === 'production') {
  // put production-specific code here
} else if (env === 'staging') {
  // put staging-specific code here
} else {
  // put dev-specific code here
}

export const apiUrl = envApiUrl
export const appName = process.env.VUE_APP_NAME || 'SURVEY-APP'
