
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IAppNotification } from '@/interfaces/common'
import mainModule from '@/store/main'

@Component
export default class NotificationsManager extends Vue {
  public show = false
  public text = ''
  public showProgress = false
  public currentNotification: IAppNotification | false = false

  public async hide() {
    this.show = false
    await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500))
  }

  public async close() {
    await this.hide()
    await this.removeCurrentNotification()
  }

  public async removeCurrentNotification() {
    if (this.currentNotification) {
      mainModule.removeNotification(this.currentNotification)
    }
  }

  public get firstNotification() {
    return mainModule.firstNotification()
  }

  public async setNotification(notification: IAppNotification | false) {
    if (this.show) {
      await this.hide()
    }
    if (notification) {
      this.currentNotification = notification
      this.showProgress = notification.showProgress || false
      this.show = true
    } else {
      this.currentNotification = false
    }
  }

  @Watch('firstNotification')
  public async onNotificationChange(
    newNotification: IAppNotification | false,
    oldNotification: IAppNotification | false
  ) {
    if (newNotification !== this.currentNotification) {
      await this.setNotification(newNotification)
      if (newNotification) {
        mainModule.actionRemoveNotification({
          notification: newNotification,
          timeout: 10000
        })
      }
    }
  }

  public get currentNotificationContent() {
    return (this.currentNotification && this.currentNotification.content) || ''
  }

  public get currentNotificationColor() {
    return (this.currentNotification && this.currentNotification.color) || 'info'
  }
}
