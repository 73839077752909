import { extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'This field is required'
})

extend('itemsRequired', {
  ...required,
  message: 'Please, add at least one {_field_}'
})

extend('email', {
  ...email,
  message: 'Invalid email address'
})
