export default {
  path: 'surveys',
  name: 'admin-survey',
  meta: { adminRequired: true },
  redirect: { name: 'admin-survey-all' },
  component: () =>
    import(
      /* webpackChunkName: "admin-survey" */
      '@/views/admin/survey/Surveys.vue'
    ),
  children: [
    {
      path: 'all',
      name: 'admin-survey-all',
      component: () =>
        import(
          /* webpackChunkName: "admin-survey" */
          '@/views/admin/survey/List.vue'
        )
    },
    {
      path: 'edit/:id',
      name: 'admin-survey-edit',
      component: () =>
        import(
          /* webpackChunkName: "admin-survey-edit" */
          '@/views/admin/survey/Edit.vue'
        )
    },
    {
      path: 'create',
      name: 'admin-survey-create',
      component: () =>
        import(
          /* webpackChunkName: "admin-survey-create" */
          '@/views/admin/survey/Create.vue'
        )
    }
  ]
}
