// Vue filters definitions
import Vue from 'vue'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import formatDistance from 'date-fns/formatDistance'

const dateFormat = 'd MMM yyyy H:mm'

Vue.filter('dateTime', (value: Date | string) => {
  let parsed: Date
  if (value instanceof Date) {
    parsed = value
  } else {
    parsed = parseISO(value)
  }
  return format(parsed, dateFormat)
})

Vue.filter('timeAgo', (value: Date) => {
  const delta = Date.now() - value.getTime()
  const relativeThreshold = 1.5 * 86400_000
  if (delta > relativeThreshold) {
    return format(value, dateFormat)
  }
  return formatDistance(value, Date.now()) + ' ago'
})
