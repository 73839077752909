
import { Component, Vue } from 'vue-property-decorator'
import NotificationsManager from '@/components/NotificationsManager.vue'
import Loader from '@/components/Loader.vue'
import mainModule from '@/store/main'

@Component({
  components: {
    NotificationsManager,
    Loader
  }
})
export default class App extends Vue {
  get loggedIn() {
    return mainModule.isLoggedIn
  }

  public async created() {
    await mainModule.actionCheckLoggedIn()
  }
}
