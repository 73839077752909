export default {
  path: 'users',
  name: 'admin-users',
  meta: { adminRequired: true },
  redirect: { name: 'admin-users-all' },
  component: () =>
    import(
      /* webpackChunkName: "admin-users" */
      '@/views/admin/users/Users.vue'
    ),
  children: [
    {
      path: 'all',
      name: 'admin-users-all',
      component: () =>
        import(
          /* webpackChunkName: "admin-users" */
          '@/views/admin/users/List.vue'
        )
    },
    {
      path: 'edit/:id',
      name: 'admin-users-edit',
      component: () =>
        import(
          /* webpackChunkName: "admin-users-edit" */
          '@/views/admin/users/Edit.vue'
        )
    },
    {
      path: 'create',
      name: 'admin-users-create',
      component: () =>
        import(
          /* webpackChunkName: "admin-users-create" */
          '@/views/admin/users/Create.vue'
        )
    }
  ]
}
