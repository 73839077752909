import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        // primary: '#51c3c8'
        // secondary: '#b0bec5',
        // accent: '#8c9eff',
        // error: '#b71c1c'
      }
    }
  }
}

export default new Vuetify(opts)
