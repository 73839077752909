export const getLocalToken = () => localStorage.getItem('token')

export const saveLocalToken = (token: string) => localStorage.setItem('token', token)

export const removeLocalToken = () => localStorage.removeItem('token')

export const convertStringsToBoolean = (
  payload: Record<string, string | boolean>
): Record<string, string | boolean> => {
  // Check value and if it's in ("True", "False") => convert to boolean
  return Object.fromEntries(
    Object.entries(payload).map(([k, v]) => {
      const _v = ('' + v).toLowerCase()
      return [k, _v === 'true' ? true : _v === 'false' ? false : v]
    })
  )
}
