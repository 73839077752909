export default {
  path: 'responses',
  name: 'admin-response',
  meta: { adminRequired: true },
  redirect: { name: 'admin-response-list' },
  component: () =>
    import(
      /* webpackChunkName: "admin-response" */
      '@/views/admin/response/Responses.vue'
    ),
  children: [
    {
      path: ':surveyId/list',
      name: 'admin-response-list',
      component: () =>
        import(
          /* webpackChunkName: "admin-response" */
          '@/views/admin/response/List.vue'
        )
    },
    {
      path: ':surveyId/chart',
      name: 'admin-response-chart',
      component: () =>
        import(
          /* webpackChunkName: "admin-response" */
          '@/views/admin/response/Charts.vue'
        )
    },
    {
      path: ':surveyId/table',
      name: 'admin-response-table',
      component: () =>
        import(
          /* webpackChunkName: "admin-response" */
          '@/views/admin/response/Table.vue'
        )
    }
  ]
}
