import * as SurveyCore from 'survey-core'
import * as SurveyVue from 'survey-vue'
import { apiUrl } from '@/env'

const countryComponent: any = {
  //Unique component name. It becomes a new question type. Please note, it should be written in lowercase.
  name: 'country',
  //The text that shows on toolbox
  title: 'Country',
  //The actual question that will do the job
  questionJSON: {
    type: 'dropdown',
    optionsCaption: 'Select a country...',
    choicesByUrl: {
      url: `${apiUrl}/api/v1/surveys/countries`
    }
  }
}

SurveyCore.ComponentCollection.Instance.add(countryComponent)
SurveyVue.ComponentCollection.Instance.add(countryComponent)

export default SurveyCore
