import Vue from 'vue'
import Router from 'vue-router'
import adminRouter from './admin'
import authRouter from './auth'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'root',
      redirect: { name: 'hello-page' },
      component: () => import(/* webpackChunkName: "base" */ '@/views/Base.vue'),
      children: [...authRouter, adminRouter]
    },
    {
      path: 'not-found',
      name: '404',
      component: () =>
        import(
          /* webpackChunkName: "404" */
          '@/views/404.vue'
        )
    },
    {
      path: '/s/:uuid',
      name: 'public-survey',
      component: () =>
        import(
          /* webpackChunkName: "public-survey" */
          '@/views/public/SurveyView.vue'
        )
    },
    {
      path: '/s/:uuid/review/:responseId',
      name: 'public-survey-review',
      component: () =>
        import(
          /* webpackChunkName: "public-survey" */
          '@/views/public/SurveyView.vue'
        )
    },
    {
      path: '*',
      name: 'hello-page',
      component: () =>
        import(
          /* webpackChunkName: "hello" */
          '@/views/Hello.vue'
        )
    }
  ]
})

export default router
