import axios, { AxiosResponse } from 'axios'
import { apiUrl } from '@/env'
import { IUserProfile, IUserProfileUpdate } from '@/interfaces/users'
import { authHeaders } from './utils'

export default {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams()
    params.append('username', username)
    params.append('password', password)

    return axios.post<URLSearchParams, AxiosResponse<{ access_token: string }>>(
      `${apiUrl}/api/v1/login/access-token`,
      params
    )
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token))
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfileUpdate, AxiosResponse<IUserProfile>>(
      `${apiUrl}/api/v1/users/me`,
      data,
      authHeaders(token)
    )
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery`, email)
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/password-reset`, {
      new_password: password,
      token
    })
  }
}
