import axios, { AxiosResponse } from 'axios'
import { apiUrl } from '@/env'
import { IUserProfile, IUserProfileCreate, IUserProfileUpdate } from '@/interfaces/users'
import { authHeaders } from './utils'

const url = `${apiUrl}/api/v1/users`

export default {
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(url, authHeaders(token))
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put<IUserProfileUpdate, AxiosResponse<IUserProfile>>(
      `${url}/${userId}`,
      data,
      authHeaders(token)
    )
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post<IUserProfileCreate, AxiosResponse<IUserProfile>>(
      url,
      data,
      authHeaders(token)
    )
  },
  async deleteUser(token: string, userId: number) {
    return axios.delete(`${url}/${userId}`, authHeaders(token))
  }
}
