import responseRoutes from './response'
import surveyRoutes from './survey'
import userRoutes from './users'

export default {
  path: 'admin',
  name: 'admin',
  meta: { authRequired: true },
  redirect: { name: 'admin-survey-all' },
  component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/Main.vue'),
  children: [
    // {
    //   path: 'dashboard',
    //   name: 'admin-dashboard',
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "admin-dashboard" */
    //       '@/views/admin/Dashboard.vue'
    //     )
    // },
    {
      path: 'settings',
      name: 'admin-settings',
      component: () =>
        import(
          /* webpackChunkName: "admin-settings" */
          '@/views/admin/Settings.vue'
        )
    },
    userRoutes,
    surveyRoutes,
    responseRoutes
  ]
}
