import axios from 'axios'
import { apiUrl } from '@/env'
import {
  ISurvey,
  IResponse,
  IResponseFull,
  ISurveyFilter,
  ISurveyFilterToApply
} from '@/interfaces/survey'
import { authHeaders } from './utils'

const url = `${apiUrl}/api/v1/surveys`

export default {
  // Survey
  async getAll(token: string) {
    return axios.get<ISurvey[]>(url, authHeaders(token))
  },
  async update(token: string, data: ISurvey) {
    return axios.put<ISurvey>(`${url}/${data.id}`, data, authHeaders(token))
  },
  async create(token: string, data: ISurvey) {
    return axios.post<ISurvey>(url, data, authHeaders(token))
  },
  async delete(token: string, uuid: string) {
    return axios.delete(`${url}/${uuid}`, authHeaders(token))
  },
  async getFiltersData(token: string, uuid: string) {
    return axios.get<ISurveyFilter[]>(`${url}/${uuid}/filters-data`, authHeaders(token))
  },

  // Public part (Get survey data and save response)
  async getOnePublic(uuid: string) {
    return axios.get<ISurvey>(`${url}/${uuid}/public`)
  },
  async sendResponse(data: IResponse) {
    return axios.post<IResponse>(`${url}/${data.survey_id}/public`, data)
  },

  // Responses for admin
  async getResponses(token: string, surveyId: string, filters: ISurveyFilterToApply[] = []) {
    return axios.get<IResponseFull[]>(`${url}/${surveyId}/responses`, {
      ...authHeaders(token),
      params: { filters: JSON.stringify(filters) }
    })
  },
  async getResponseById(token: string, surveyId: string, responseId: string) {
    return axios.get<IResponseFull>(
      `${url}/${surveyId}/responses/${responseId}`,
      authHeaders(token)
    )
  },
  async deleteResponseById(token: string, surveyId: string, responseId: string) {
    return axios.delete(`${url}/${surveyId}/responses/${responseId}`, authHeaders(token))
  }
}
